<template>
   <v-card flat :color="dark" class="">

        <v-card-text >
          <v-skeleton-loader
            class="transparent"
            v-if="loading"
            v-bind="attrs"
            type="list-item-avatar-three-line, paragraph"
          ></v-skeleton-loader>
          <writerComponent :detail="detail" v-else />
        </v-card-text>
        
        <v-card :color="ant" class="mx-4 ">
          <v-card-title>
            About me
          </v-card-title>
          
          <v-card-text class="word-break">
            <v-skeleton-loader
            class="transparent"
            v-if="loading"
            v-bind="attrs"
            type="list-item-avatar-three-line, paragraph@5"
          ></v-skeleton-loader>
            <span style="white-space: pre-line" v-html="detail.long_about" v-else> </span>
          </v-card-text>
        </v-card>

        <v-card :color="ant" class="mx-4 mt-5 ">
          <v-card-title>
            Customer reviews
          </v-card-title>
          <v-card-text class="pb-10">
            <v-skeleton-loader
            class="transparent"
            v-if="loading2"
            v-bind="attrs"
            type="list-item-avatar-three-line, paragraph"
          ></v-skeleton-loader>
            <PlaceHolder
              v-if="reviews.length === 0"
              :src="require('@/assets/img/svg/no-notifications.svg')"
              title="No writers Found."
              text=""
            />
            <div v-for="(review, index) in reviews" :key="index" v-else>
              <reviewComponent :review="review" class="mt-5" />
            </div>

             <div class="text-center mt-5" v-if="reviews.length !== 0 && page !== lastPage">
                <v-btn text outlined @click="showMore" class="" large> Show more </v-btn>
              </div>
          </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "profileComponent",
  components: {
    writerComponent: () =>
      import("@/modules/Writers/components/writerComponent"),
    reviewComponent: () =>
      import("@/modules/Writers/components/reviewComponent"),
    PlaceHolder: () => import('@/components/core/PlaceHolder'),
  },
  props: ['id'],
  mixins: [colorMxn],
  data() {
    return {
      reviews: [],
      detail: {},
      loading: false,
      loading2: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      page: 1,
      total: 0,
      lastPage: 0,
      perPage: 0,
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  watch: {
    id() {
      this.reviews = [],
      this.page = 1;
      this.total = 0;
      this.lastPage = 0;
      this.perPage = 0;
      this.getWriterProfile();
      this.getReviews();
    },
  },
  mounted() {
    this.getWriterProfile();
    this.getReviews()
  },
  methods: {
    ...mapActions(['performGetActions']),
    async getWriterProfile() {
      this.loading = true;
      const fullPayload = {
        endpoint: `/profile/writer/${this.id}`
      }

      const response = await this.performGetActions(fullPayload);
      this.loading = false;
      this.detail = response.data[0];
    },
    async getReviews() {
      this.loading2 = true;
      const payload = {
        page: this.page,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/rating/show/${this.id}`,
      }
      
      const response = await this.performGetActions(fullPayload);
      this.loading2 = false;
      this.page = response.data.page;
      this.total = response.data.total;
      this.lastPage = response.data.lastPage;
      this.perPage = response.data.perPage;
      response.data.data.forEach(obj => {
        this.reviews.push(obj);
      })
    },
    showMore() {
      this.page = this.page + 1;
      this.getReviews();
    },
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
